import React, { Component } from "react";
import { Icon } from 'antd'
import axios from 'axios';
import { API_URL, SCHEDULER_DATA_CONFIG } from "../config.js";

import BigScheduler, {
  SchedulerData,
  ViewTypes,
  CellUnits,
  DATE_FORMAT
} from "react-big-scheduler";

import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import 'moment/locale/ja'
import moment from "moment";
import { formatDate, hankaku2Zenkaku, blackOrWhite } from '../Function/Function';
import Loading from "./Loading/Loading";
import ClickEventModal from "./Modal/ClickEventModal";
import MoveEventModal from "./Modal/MoveEventModal";
import NavBar from "./NavBar/NavBar";

import "react-big-scheduler/lib/css/style.css";
import './Scheduler.css';
import User from "../User.js";

class Scheduler extends Component {
  constructor(props) {
    super(props);

    //====================================
    //表の基本設定
    //====================================
    //日付の初期値をセット
    const initdate = new Date();
    
    //CHANGED ADD 2023/07/12 #19
    // ローカルストレージから週次・月次切替情報を取得
    const value = localStorage.getItem("viewType");
    const view = value
      ? JSON.parse(value)
      : {
          viewType: ViewTypes.Month,
          showAgenda: false,
          isEventPerspective: false,
        };

    //表の基本設定をセット
    let schedulerData = new SchedulerData(
      initdate,
      view.viewType,
      view.showAgenda,
      view.isEventPerspective,
      SCHEDULER_DATA_CONFIG,
      {
        getCustomDateFunc: this.getCustomDate,
        getDateLabelFunc: this.getDateLabel,
      },
      moment
    );

    this.state = {
      viewModel: schedulerData,
      clickEventModalIsOpen: false,
      moveEventModalIsOpen: false,
      modalParam: null,
      linestatus: [],
      lines: null,
      articles: null,
      selectedSort: "ライン別",
      selectedLine: null,
      selectedArticle: null,
      checkedValues: [],
      scale: 1,
      isLoading: true
    };

    this.handleClickEventCloseModal = this.handleClickEventCloseModal.bind(this);
    this.clickEventRefStatus = React.createRef();
    this.clickEventRefText = React.createRef();

    this.handleMoveEventCloseModal = this.handleMoveEventCloseModal.bind(this);
  }

  //====================================
  //初回読込
  //====================================
  componentDidMount() {
    //CHANGED UPDATE 2023/07/12 #19
    const startDate = new Date(this.state.viewModel.startDate);
    const endDate = new Date(this.state.viewModel.endDate);

    // 絞り込み情報を取得
    let selectedSort = this.state.selectedSort;
    let lines = null;
    let articles = null;

    this.getFMSchdules(startDate, endDate, selectedSort, lines, articles)
      .then(res =>
        // レンダー情報のセット
        this.setState({
          viewModel: res.schedulerData,
          linestatus: res.linestatus,
          lines: res.lines,
          articles: res.articles,
          selectedLine: null,
          selectedArticle: null,
          checkedValues: res.checkedValues,
          isLoading: false
        })
      );
  }

  clickEventOpenModal(modalParam) {
    this.setState({
      clickEventModalIsOpen: true,
      modalParam: modalParam
    });
  }

  handleClickEventCloseModal() {
    this.setState({ clickEventModalIsOpen: false });
  }

  moveEventOpenModal(modalParam) {
    this.setState({
      moveEventModalIsOpen: true,
      modalParam: modalParam
    });
  }

  handleMoveEventCloseModal() {
    this.setState({ moveEventModalIsOpen: false });
  }

  handleSortChange = (value, schedulerData) => {
    schedulerData.setResources([]);
    schedulerData.setEvents([]);

    // 絞り込み情報を取得
    let selectedSort = value;
    let selectedLine = null;
    let selectedArticle = null;

    // レンダー情報のリセット
    this.setState({
      viewModel: schedulerData,
      selectedSort: selectedSort,
      selectedLine: selectedLine,
      selectedArticle: selectedArticle,
      isLoading: true
    }, () => {
      let startDate = schedulerData.startDate;
      let endDate = schedulerData.endDate;

      this.getFMSchdules(startDate, endDate, selectedSort, selectedLine, selectedArticle)
        .then(res =>
          // レンダー情報のセット
          this.setState({
            viewModel: res.schedulerData,
            linestatus: res.linestatus,
            lines: res.lines,
            articles: res.articles,
            isLoading: false
          })
        )
    });
  }

  handleLineChange = (value, schedulerData, selectedSort, selectedArticle) => {
    schedulerData.setResources([]);
    schedulerData.setEvents([]);

    // 絞り込み情報を取得
    let selectedLine = value;
    if (selectedSort === 'ライン別' && selectedLine !== this.state.selectedLine) {
      selectedArticle = null;
    }

    // レンダー情報のリセット
    this.setState({
      viewModel: schedulerData,
      selectedSort: selectedSort,
      selectedLine: selectedLine,
      selectedArticle: selectedArticle,
      isLoading: true
    }, () => {
      let startDate = schedulerData.startDate;
      let endDate = schedulerData.endDate;

      this.getFMSchdules(startDate, endDate, selectedSort, selectedLine, selectedArticle)
        .then(res =>
          // レンダー情報のセット
          this.setState({
            viewModel: res.schedulerData,
            linestatus: res.linestatus,
            lines: res.lines,
            articles: res.articles,
            isLoading: false
          })
        )
    });
  }

  handleArticleChange = (value, schedulerData, selectedSort, selectedLine) => {
    schedulerData.setResources([]);
    schedulerData.setEvents([]);

    // 絞り込み情報を取得
    let selectedArticle = value;
    if (selectedSort === '物件別' && selectedArticle !== this.state.selectedArticle) {
      selectedLine = null;
    }

    // レンダー情報のリセット
    this.setState({
      viewModel: schedulerData,
      selectedSort: selectedSort,
      selectedLine: selectedLine,
      selectedArticle: selectedArticle,
      isLoading: true
    }, () => {
      let startDate = schedulerData.startDate;
      let endDate = schedulerData.endDate;

      this.getFMSchdules(startDate, endDate, selectedSort, selectedLine, selectedArticle)
        .then(res =>
          // レンダー情報のセット
          this.setState({
            viewModel: res.schedulerData,
            linestatus: res.linestatus,
            lines: res.lines,
            articles: res.articles,
            isLoading: false
          })
        )
    });
  }

  handlePrevClick = (schedulerData) => {
    schedulerData.setResources([]);
    schedulerData.setEvents([]);
    schedulerData.prev();

    // 絞り込み情報を取得
    let selectedSort = this.state.selectedSort;
    // CHANGED:UPDATE 2023/07/21 #31
    // let selectedLine = null;
    // let selectedArticle = null;
    let selectedLine = this.state.selectedLine;
    let selectedArticle = this.state.selectedArticle;

    // レンダー情報のリセット
    this.setState({
      viewModel: schedulerData,
      selectedSort: selectedSort,
      selectedLine: selectedLine,
      selectedArticle: selectedArticle,
      isLoading: true
    }, () => {
      let startDate = schedulerData.startDate;
      let endDate = schedulerData.endDate;

      this.getFMSchdules(startDate, endDate, selectedSort, selectedLine, selectedArticle)
        .then(res =>
          // レンダー情報のセット
          this.setState({
            viewModel: res.schedulerData,
            linestatus: res.linestatus,
            lines: res.lines,
            articles: res.articles,
            isLoading: false
          })
        )
    });
  };

  handleNextClick = (schedulerData) => {
    schedulerData.setResources([]);
    schedulerData.setEvents([]);

    if (schedulerData.viewType === ViewTypes.Custom) {
      let startDate = schedulerData.startDate;
      schedulerData.startDate = schedulerData.localeMoment(startDate).add(30, 'days').format(DATE_FORMAT);
    }

    schedulerData.next();

    // 絞り込み情報を取得
    let selectedSort = this.state.selectedSort;
    // CHANGED:UPDATE 2023/07/21 #31
    // let selectedLine = null;
    // let selectedArticle = null;
    let selectedLine = this.state.selectedLine;
    let selectedArticle = this.state.selectedArticle;

    // レンダー情報のリセット
    this.setState({
      viewModel: schedulerData,
      selectedSort: selectedSort,
      selectedLine: selectedLine,
      selectedArticle: selectedArticle,
      isLoading: true
    }, () => {
      let startDate = schedulerData.startDate;
      let endDate = schedulerData.endDate;

      this.getFMSchdules(startDate, endDate, selectedSort, selectedLine, selectedArticle)
        .then(res =>
          // レンダー情報のセット
          this.setState({
            viewModel: res.schedulerData,
            linestatus: res.linestatus,
            lines: res.lines,
            articles: res.articles,
            isLoading: false
          })
        )
    });
  };

  handleViewChange = (schedulerData, view) => {
    schedulerData.setResources([]);
    schedulerData.setEvents([]);
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );

    //CHANGED ADD 2023/07/12 #19
    // ローカルストレージに週次・月次切替情報を保存
    localStorage.setItem("viewType", JSON.stringify(view));

    // 絞り込み情報を取得
    let selectedSort = this.state.selectedSort;
    // CHANGED:UPDATE 2023/07/21 #31
    // let selectedLine = null;
    // let selectedArticle = null;
    let selectedLine = this.state.selectedLine;
    let selectedArticle = this.state.selectedArticle;

    // レンダー情報のリセット
    this.setState({
      viewModel: schedulerData,
      selectedSort: selectedSort,
      selectedLine: selectedLine,
      selectedArticle: selectedArticle,
      isLoading: true
    }, () => {
      let startDate = new Date(schedulerData.startDate);
      let endDate = new Date(schedulerData.endDate);

      this.getFMSchdules(startDate, endDate, selectedSort, selectedLine, selectedArticle)
        .then(res =>
          // レンダー情報のセット
          this.setState({
            viewModel: res.schedulerData,
            linestatus: res.linestatus,
            lines: res.lines,
            articles: res.articles,
            isLoading: false
          })
        )
    });
  };

  handleSelectDate = (schedulerData, date) => {
    schedulerData.setResources([]);
    schedulerData.setEvents([]);
    schedulerData.setDate(date);

    // 絞り込み情報を取得
    let selectedSort = this.state.selectedSort;
    let selectedLine = null;
    let selectedArticle = null;

    // レンダー情報のリセット
    this.setState({
      viewModel: schedulerData,
      selectedSort: selectedSort,
      selectedLine: selectedLine,
      selectedArticle: selectedArticle,
      isLoading: true
    }, () => {
      let startDate = new Date(schedulerData.startDate);
      let endDate = new Date(schedulerData.endDate);

      this.getFMSchdules(startDate, endDate, selectedSort, selectedLine, selectedArticle)
        .then(res =>
          // レンダー情報のセット
          this.setState({
            viewModel: res.schedulerData,
            linestatus: res.linestatus,
            lines: res.lines,
            articles: res.articles,
            isLoading: false
          })
        )
    });
  };

  eventClicked = (schedulerData, event) => {
    let date = schedulerData.localeMoment(event.start).format("YYYY/MM/DD")

    const modalParam = schedulerData.resources.find((v) => v.id === event.resourceId).partflg ?
      {
        UUID: event.id,
        製品番号: "",
        立米: "",
        配合: "",
        ステータス: "",
        出荷日テキスト: event.出荷日テキスト,
        出荷日テキストフラグ: true,
        日付: date,
        resourceId: event.resourceId
      } :
      {
        UUID: event.id,
        製品番号: event.製品番号,
        立米: event.立米,
        配合: event.配合,
        ステータス: event.ステータス,
        備考: event.備考,
        備考フラグ: true,
        日付: date,
        resourceId: event.resourceId
      }

    this.clickEventOpenModal(modalParam);
  };

  handleSelectedDateUpdateButtonClicked = async (schedulerData, UUID) => {
    let renderData = schedulerData.renderData;
    let events = schedulerData.events;

    if (window.confirm(`更新してもよろしいですか?`)) {
      let layout = 'D1001_dataapi';
      let script = 'D1003_更新[DataAPIMap]';
      let scriptparam = {
        UUID: UUID,
        ステータス: this.clickEventRefStatus.current.value,
        備考: this.clickEventRefText.current.value,
        従業員UUID: User.getUserId()
      }
      let json = {
        'layout': layout,
        'data': {
          'query': [],
          'script.prerequest': script,
          'script.prerequest.param': new Buffer(JSON.stringify(scriptparam)).toString('base64')
        }
      };

      try {
        const result = (await axios.post(API_URL + '/filemaker', json)).data;
        const scriptResult = JSON.parse(decodeURIComponent(escape(window.atob(result.response['scriptResult.prerequest']))));
        const fmevents = JSON.parse(scriptResult.fmevents);

        const error_code = parseInt(scriptResult.error_code, 10);
        if (error_code !== 0) {
          window.alert("更新に失敗しました。時間をおいて再度お試しください。");
          this.setState({ clickEventModalIsOpen: false });
          return;
        }

        events.forEach(item => {
          const res = fmevents.find((v) => v.id === item.id);
          if (res) {
            item.ステータス = res.ステータス;
            item.備考 = res.備考;
          }
        });
      } catch {
        window.alert("更新に失敗しました。時間をおいて再度お試しください。");
        this.setState({ clickEventModalIsOpen: false });
        return;
      }

      schedulerData.setEvents(events);
      renderData.filter((v) => v.expanded === false)
        .forEach(item => {
          schedulerData.toggleExpandStatus(item.slotId);
        });

      this.setState({
        viewModel: schedulerData,
        clickEventModalIsOpen: false
      });
    }
  }

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    const date = schedulerData.localeMoment(start).format("YYYY/MM/DD")

    const modalParam = schedulerData.resources.find((v) => v.id === slotId).partflg ?
      {
        UUID: "",
        製品番号: "",
        立米: "",
        配合: "",
        ステータス: "",
        出荷日テキスト: "",
        出荷日テキストフラグ: true,
        備考フラグ: false,
        日付: date,
        resourceId: slotId
      } :
      {
        UUID: "",
        製品番号: "",
        立米: "",
        配合: "",
        ステータス: "",
        備考: "",
        備考フラグ: true,
        日付: date,
        resourceId: slotId
      }

    this.clickEventOpenModal(modalParam);
  };

  handleBikouUpdateButtonClicked = async (schedulerData, UUID, resourceId, date) => {
    let renderData = schedulerData.renderData;
    let events = schedulerData.events;
    let str = resourceId.split('|');

    const articleUUID = str[0];
    let katawaku = "";
    let buiCode = "";
    let lineCode = "";

    if (this.state.selectedSort === "ライン別") {
      katawaku = str[2];
      buiCode = str[1];
      lineCode = str[3];
    }
    else if (this.state.selectedSort === "物件別") {
      katawaku = str[3];
      buiCode = str[2];
      lineCode = str[1];
    }

    if (window.confirm(`更新してもよろしいですか?`)) {
      let layout = 'D1001_dataapi';
      let script = 'D1003_フリーテキストのみ調整[DataAPI]';
      let bikou = this.clickEventRefText.current.value;
      let scriptparam = {
        物件UUID: articleUUID,
        部位コード: buiCode,
        製造ラインコード: lineCode,
        型枠番号: katawaku,
        日付: date,
        備考: bikou,
        従業員UUID: User.getUserId()
      }
      let json = {
        'layout': layout,
        'data': {
          'query': [],
          'script.prerequest': script,
          'script.prerequest.param': new Buffer(JSON.stringify(scriptparam)).toString('base64')
        }
      };

      try {
        const result = (await axios.post(API_URL + '/filemaker', json)).data;
        const scriptResult = JSON.parse(decodeURIComponent(escape(window.atob(result.response['scriptResult.prerequest']))));

        const error_code = parseInt(scriptResult.error_code, 10);
        if (error_code !== 0) {
          window.alert("更新に失敗しました。時間をおいて再度お試しください。");
          this.setState({ clickEventModalIsOpen: false });
          return;
        }

        if (bikou) {
          const fmevents = JSON.parse(scriptResult.fmevents);

          fmevents.forEach(item => {
            const res = events.find((v) => v.備考UUID === item.id);
            if (res) {
              res.備考 = item.備考;
            } else {
              var event = {
                id: item.id,
                備考: item.備考,
                備考UUID: item.id,
                start: item.start,
                end: item.end,
                resourceId: resourceId,
                title: ""
              }

              events.push(event);
            }
          });
        } else {
          events.splice(events.findIndex(v => v.備考UUID === UUID), 1);
        }
      } catch {
        window.alert("更新に失敗しました。時間をおいて再度お試しください。");
        this.setState({ clickEventModalIsOpen: false });
        return;
      }

      schedulerData.setEvents(events);
      renderData.filter((v) => v.expanded === false)
        .forEach(item => {
          schedulerData.toggleExpandStatus(item.slotId);
        });

      this.setState({
        viewModel: schedulerData,
        clickEventModalIsOpen: false
      });
    }
  }

  handleSyukkaUpdateButtonClicked = async (schedulerData, UUID, resourceId, date) => {
    let renderData = schedulerData.renderData;
    let events = schedulerData.events;
    let str = resourceId.split('|');

    const articleUUID = str[0];
    let buiCode = "";
    let lineCode = "";

    if (this.state.selectedSort === "ライン別") {
      buiCode = str[1];
      lineCode = "";
    }
    else if (this.state.selectedSort === "物件別") {
      buiCode = str[2];
      lineCode = str[1];
    }

    if (window.confirm(`更新してもよろしいですか?`)) {
      let layout = 'D1001_dataapi';
      let script = 'D1003_出荷日テキストのみ調整[DataAPI]';
      let text = this.clickEventRefText.current.value;
      let scriptparam = {
        物件UUID: articleUUID,
        部位コード: buiCode,
        製造ラインコード: lineCode,
        日付: date,
        出荷日テキスト: text,
        従業員UUID: User.getUserId()
      }
      let json = {
        'layout': layout,
        'data': {
          'query': [],
          'script.prerequest': script,
          'script.prerequest.param': new Buffer(JSON.stringify(scriptparam)).toString('base64')
        }
      };

      try {
        const result = (await axios.post(API_URL + '/filemaker', json)).data;
        const scriptResult = JSON.parse(decodeURIComponent(escape(window.atob(result.response['scriptResult.prerequest']))));

        const error_code = parseInt(scriptResult.error_code, 10);
        if (error_code !== 0) {
          window.alert("更新に失敗しました。時間をおいて再度お試しください。");
          this.setState({ clickEventModalIsOpen: false });
          return;
        }

        if (text) {
          const fmevents = JSON.parse(scriptResult.fmevents);

          fmevents.forEach(item => {
            const res = events.find((v) => v.出荷日テキストUUID === item.id);
            if (res) {
              res.出荷日テキスト = item.出荷日テキスト;
            } else {
              var event = {
                id: item.id,
                出荷日テキスト: item.出荷日テキスト,
                出荷日テキストUUID: item.id,
                start: item.start,
                end: item.end,
                resourceId: resourceId,
                title: ""
              }

              events.push(event);
            }
          });
        } else {
          events.splice(events.findIndex(v => v.出荷日テキストUUID === UUID), 1);
        }
      } catch {
        window.alert("更新に失敗しました。時間をおいて再度お試しください。");
        this.setState({ clickEventModalIsOpen: false });
        return;
      }

      schedulerData.setEvents(events);
      renderData.filter((v) => v.expanded === false)
        .forEach(item => {
          schedulerData.toggleExpandStatus(item.slotId);
        });

      this.setState({
        viewModel: schedulerData,
        clickEventModalIsOpen: false
      });
    }
  }

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    if (schedulerData.resources.find((v) => v.id === slotId).partflg) {
      return;
    }

    const beforeArticleUUID = event.物件UUID;
    const beforeKatawaku = event.型枠番号;
    const beforeBuiCode = event.部位コード;
    const beforeLineCode = event.製造ラインコード;

    // CHANGED:ADD 2023/7/12 #20
    const beforeLineName = event.製造ライン名称;
    const afterLineName = slotName.split('|')[0];

    const after = slotId.split('|');
    const afterArticleUUID = after[0];
    let afterKatawaku = "";
    let afterBuiCode = "";
    let afterLineCode = "";

    if (this.state.selectedSort === "ライン別") {
      afterKatawaku = after[2];
      afterBuiCode = after[1];
      afterLineCode = after[3];
    }
    else if (this.state.selectedSort === "物件別") {
      afterKatawaku = after[3];
      afterBuiCode = after[2];
      afterLineCode = after[1];
    }

    const beforeDate = schedulerData.localeMoment(event.start).format("YYYY/MM/DD")
    const afterDate = schedulerData.localeMoment(start).format("YYYY/MM/DD")
    const endDate = schedulerData.localeMoment(schedulerData.endDate).format("YYYY/MM/DD")

    if (event.製品番号 &&
      (beforeArticleUUID === afterArticleUUID) && (beforeBuiCode === afterBuiCode)) {
      if ((beforeKatawaku === afterKatawaku) && (beforeLineCode === afterLineCode) && (beforeDate !== afterDate)) {
        const modalParam = {
          UUID: event.id,
          製品番号: event.製品番号,
          変更元型枠番号: beforeKatawaku,
          変更元予定日: beforeDate,
          変更先予定日: afterDate,
          最終日: endDate,
          変更元製造ラインコード: beforeLineCode,
          型枠調整フラグ: false,
          製造ライン調整フラグ: false,
        }

        this.moveEventOpenModal(modalParam);
        // CHANGED:ADD 2023/7/12 #20
      } else if ((beforeKatawaku === afterKatawaku) && (beforeLineCode !== afterLineCode)) {
        const modalParam = {
          UUID: event.id,
          製品番号: event.製品番号,
          変更先リソースID: slotId,
          変更元型枠番号: beforeKatawaku,
          変更先型枠番号: afterKatawaku,
          変更元予定日: beforeDate,
          変更先予定日: afterDate,
          最終日: endDate,
          変更元製造ラインコード: beforeLineCode,
          変更先製造ラインコード: afterLineCode,
          変更元製造ライン名称: beforeLineName,
          変更先製造ライン名称: afterLineName,
          型枠調整フラグ: false,
          製造ライン調整フラグ: true,
        }

        this.moveEventOpenModal(modalParam);
      } else if ((beforeKatawaku !== afterKatawaku) || (beforeLineCode !== afterLineCode)) {
        const modalParam = {
          UUID: event.id,
          製品番号: event.製品番号,
          変更先リソースID: slotId,
          変更元型枠番号: beforeKatawaku,
          変更先型枠番号: afterKatawaku,
          変更元予定日: beforeDate,
          変更先予定日: afterDate,
          最終日: endDate,
          変更元製造ラインコード: beforeLineCode,
          変更先製造ラインコード: afterLineCode,
          型枠調整フラグ: true,
          製造ライン調整フラグ: false,
        }

        this.moveEventOpenModal(modalParam);
      }
    }
  };

  handleSelectedDateMoveButtonClicked = async (schedulerData, UUID, afterDate, lineCode) => {
    let renderData = schedulerData.renderData;
    let events = schedulerData.events;
    let selectedLine = this.state.selectedLine == null ? '' : this.state.selectedLine.value;
    let selectedArticle = this.state.selectedArticle == null ? '' : this.state.selectedArticle.value;

    if (window.confirm(`更新してもよろしいですか?`)) {
      // 選択日のみ調整スクリプト実行
      let layout = 'D1001_dataapi';
      let script = 'D1003_選択日のみ調整[DataAPIMap]';
      let scriptparam = {
        UUID: UUID,
        変更先予定日: afterDate,
        従業員UUID: User.getUserId(),
        start: schedulerData.startDate,
        end: schedulerData.endDate,
        変更元製造ラインコード: lineCode,
        製造ラインコード: selectedLine,
        物件UUID: selectedArticle,
      }
      let json = {
        'layout': layout,
        'data': {
          'query': [],
          'script.prerequest': script,
          'script.prerequest.param': new Buffer(JSON.stringify(scriptparam)).toString('base64')
        }
      };

      await axios.post(API_URL + '/filemaker', json)
        .then((result) => {
          const scriptResult = JSON.parse(decodeURIComponent(escape(window.atob(result.data.response['scriptResult.prerequest']))));
          const error_code = parseInt(scriptResult.error_code, 10);

          if (error_code !== 0) {
            return Promise.reject(scriptResult);
          }

          const fmevents = JSON.parse(scriptResult.fmevents);

          events.forEach(item => {
            const res = fmevents.find((v) => v.id === item.id);
            if (res) {
              item.start = res.start;
              item.end = res.end;
            }
          })

          schedulerData.setEvents(events);
          renderData.filter((v) => v.expanded === false)
            .forEach(item => {
              schedulerData.toggleExpandStatus(item.slotId);
            });

          this.setState({
            viewModel: schedulerData,
            moveEventModalIsOpen: false
          });
        })
        .catch((error) => {
          console.log(error);
          window.alert("更新に失敗しました。時間をおいて再度お試しください。");
          this.setState({ moveEventModalIsOpen: false });
        });

      // 歩掛更新スクリプト実行
      layout = 'D1001_dataapi';
      script = 'D1003_製品移動_歩掛更新[DataAPIMap]';
      json = {
        'layout': layout,
        'data': {
          'query': [],
          'script.prerequest': script,
          'script.prerequest.param': new Buffer(JSON.stringify(scriptparam)).toString('base64')
        }
      };

      await axios.post(API_URL + '/filemaker', json)
        .then((result) => {
          const scriptResult = JSON.parse(decodeURIComponent(escape(window.atob(result.data.response['scriptResult.prerequest']))));
          const error_code = parseInt(scriptResult.error_code, 10);

          if (error_code !== 0) {
            return Promise.reject(scriptResult);
          }

          const bugakarijson = JSON.parse(scriptResult.bugakari);
          for (let i = 0; i < schedulerData.headers.length; i++) {
            let date = schedulerData.localeMoment(schedulerData.headers[i].time);
            let header_date = formatDate(new Date(date), "YYYY/MM/DD");
            let bugakari = bugakarijson[header_date];

            if (bugakari) {
              if (bugakari.休日 !== true) {
                schedulerData.headers[i].nonWorkingTime = false;
              } else {
                schedulerData.headers[i].nonWorkingTime = true;
              }

              schedulerData.headers[i].型枠数 = bugakari.型枠数;
              schedulerData.headers[i].打設数 = bugakari.打設数;
              schedulerData.headers[i].打設量 = bugakari.打設量;
              schedulerData.headers[i].計画人工合計 = bugakari.計画人工合計;
              schedulerData.headers[i].配置予定人工合計 = bugakari.配置予定人工合計;
              schedulerData.headers[i].実施人工合計 = bugakari.実施人工合計;
              schedulerData.headers[i].差異 = bugakari.差異;

              schedulerData.headers[i].休日 = bugakari.休日;
            }
          }

          this.setState({
            viewModel: schedulerData,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  handleSelectedDateAndLaterMoveButtonClicked = async (schedulerData, UUID, beforeDate, afterDate, endDate, lineCode) => {
    let renderData = schedulerData.renderData;
    let events = schedulerData.events;
    let selectedLine = this.state.selectedLine == null ? '' : this.state.selectedLine.value;
    let selectedArticle = this.state.selectedArticle == null ? '' : this.state.selectedArticle.value;

    if (window.confirm(`更新してもよろしいですか?`)) {
      // 選択日および以降の調整スクリプト実行
      let layout = 'D1001_dataapi';
      let script = 'D1003_選択日および以降の調整[DataAPIMap]';
      let scriptparam = {
        UUID: UUID,
        変更元予定日: beforeDate,
        変更先予定日: afterDate,
        最終日: endDate,
        従業員UUID: User.getUserId(),
        start: schedulerData.startDate,
        end: schedulerData.endDate,
        変更元製造ラインコード: lineCode,
        製造ラインコード: selectedLine,
        物件UUID: selectedArticle,
      }
      let json = {
        'layout': layout,
        'data': {
          'query': [],
          'script.prerequest': script,
          'script.prerequest.param': new Buffer(JSON.stringify(scriptparam)).toString('base64')
        }
      };

      await axios.post(API_URL + '/filemaker', json)
        .then((result) => {
          const scriptResult = JSON.parse(decodeURIComponent(escape(window.atob(result.data.response['scriptResult.prerequest']))));
          const error_code = parseInt(scriptResult.error_code, 10);

          if (error_code !== 0) {
            return Promise.reject(scriptResult);
          }

          const fmevents = JSON.parse(scriptResult.fmevents);

          events.forEach(item => {
            const res = fmevents.find((v) => v.id === item.id);
            if (res) {
              item.start = res.start;
              item.end = res.end;
            }
          });

          schedulerData.setEvents(events);
          renderData.filter((v) => v.expanded === false)
            .forEach(item => {
              schedulerData.toggleExpandStatus(item.slotId);
            });

          this.setState({
            viewModel: schedulerData,
            moveEventModalIsOpen: false
          });
        })
        .catch((error) => {
          console.log(error);
          window.alert("更新に失敗しました。時間をおいて再度お試しください。");
          this.setState({ moveEventModalIsOpen: false });
        });

      // 歩掛更新スクリプト実行
      layout = 'D1001_dataapi';
      script = 'D1003_製品移動_歩掛更新[DataAPIMap]';
      json = {
        'layout': layout,
        'data': {
          'query': [],
          'script.prerequest': script,
          'script.prerequest.param': new Buffer(JSON.stringify(scriptparam)).toString('base64')
        }
      };

      await axios.post(API_URL + '/filemaker', json)
        .then((result) => {
          const scriptResult = JSON.parse(decodeURIComponent(escape(window.atob(result.data.response['scriptResult.prerequest']))));
          const error_code = parseInt(scriptResult.error_code, 10);

          if (error_code !== 0) {
            return Promise.reject(scriptResult);
          }

          const bugakarijson = JSON.parse(scriptResult.bugakari);
          for (let i = 0; i < schedulerData.headers.length; i++) {
            let date = schedulerData.localeMoment(schedulerData.headers[i].time);
            let header_date = formatDate(new Date(date), "YYYY/MM/DD");
            let bugakari = bugakarijson[header_date];

            if (bugakari) {
              if (bugakari.休日 !== true) {
                schedulerData.headers[i].nonWorkingTime = false;
              } else {
                schedulerData.headers[i].nonWorkingTime = true;
              }

              schedulerData.headers[i].型枠数 = bugakari.型枠数;
              schedulerData.headers[i].打設数 = bugakari.打設数;
              schedulerData.headers[i].打設量 = bugakari.打設量;
              schedulerData.headers[i].計画人工合計 = bugakari.計画人工合計;
              schedulerData.headers[i].配置予定人工合計 = bugakari.配置予定人工合計;
              schedulerData.headers[i].実施人工合計 = bugakari.実施人工合計;
              schedulerData.headers[i].差異 = bugakari.差異;

              schedulerData.headers[i].休日 = bugakari.休日;
            }
          }

          this.setState({
            viewModel: schedulerData,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  handleKatawakuMoveButtonClicked = async (schedulerData, UUID, afterResourceId, afterkatawaku, beforeDate, afterDate, beforelineCode, afterlineCode) => {
    let renderData = schedulerData.renderData;
    let events = schedulerData.events;
    let selectedLine = this.state.selectedLine == null ? '' : this.state.selectedLine.value;
    let selectedArticle = this.state.selectedArticle == null ? '' : this.state.selectedArticle.value;

    // 型枠跨ぎ調整スクリプト実行
    if (window.confirm(`更新してもよろしいですか?`)) {
      let layout = 'D1001_dataapi';
      let script = 'D1003_型枠跨ぎ調整[DataAPIMap]';
      let scriptparam = {
        UUID: UUID,
        変更元予定日: beforeDate,
        変更先予定日: afterDate,
        変更先_型枠番号: afterkatawaku,
        従業員UUID: User.getUserId(),
        start: schedulerData.startDate,
        end: schedulerData.endDate,
        変更元製造ラインコード: beforelineCode,
        変更先製造ラインコード: afterlineCode,
        製造ラインコード: selectedLine,
        物件UUID: selectedArticle,
      }
      let json = {
        'layout': layout,
        'data': {
          'query': [],
          'script.prerequest': script,
          'script.prerequest.param': new Buffer(JSON.stringify(scriptparam)).toString('base64')
        }
      };

      await axios.post(API_URL + '/filemaker', json)
        .then((result) => {
          const scriptResult = JSON.parse(decodeURIComponent(escape(window.atob(result.data.response['scriptResult.prerequest']))));
          const error_code = parseInt(scriptResult.error_code, 10);

          if (error_code !== 0) {
            return Promise.reject(scriptResult);
          }

          const fmevents = JSON.parse(scriptResult.fmevents);

          events.forEach(item => {
            const res = fmevents.find((v) => v.id === item.id);
            if (res) {
              item.resourceId = afterResourceId;
              item.start = res.start;
              item.end = res.end;
              item.製造ラインコード = res.製造ラインコード;
              item.製造ライン名称 = res.製造ライン名称;
              item.型枠番号 = res.型枠番号;
            }
          });

          schedulerData.setEvents(events);
          renderData.filter((v) => v.expanded === false)
            .forEach(item => {
              schedulerData.toggleExpandStatus(item.slotId);
            });

          this.setState({
            viewModel: schedulerData,
            moveEventModalIsOpen: false
          });
        })
        .catch((error) => {
          console.log(error);
          window.alert("更新に失敗しました。時間をおいて再度お試しください。");
          this.setState({ moveEventModalIsOpen: false });
        });

      // 歩掛更新スクリプト実行
      layout = 'D1001_dataapi';
      script = 'D1003_型枠移動_歩掛更新[DataAPIMap]';
      json = {
        'layout': layout,
        'data': {
          'query': [],
          'script.prerequest': script,
          'script.prerequest.param': new Buffer(JSON.stringify(scriptparam)).toString('base64')
        }
      };

      await axios.post(API_URL + '/filemaker', json)
        .then((result) => {
          const scriptResult = JSON.parse(decodeURIComponent(escape(window.atob(result.data.response['scriptResult.prerequest']))));
          const error_code = parseInt(scriptResult.error_code, 10);

          if (error_code !== 0) {
            return Promise.reject(scriptResult);
          }

          const bugakarijson = JSON.parse(scriptResult.bugakari);
          for (let i = 0; i < schedulerData.headers.length; i++) {
            let date = schedulerData.localeMoment(schedulerData.headers[i].time);
            let header_date = formatDate(new Date(date), "YYYY/MM/DD");
            let bugakari = bugakarijson[header_date];

            if (bugakari) {
              if (bugakari.休日 !== true) {
                schedulerData.headers[i].nonWorkingTime = false;
              } else {
                schedulerData.headers[i].nonWorkingTime = true;
              }

              schedulerData.headers[i].型枠数 = bugakari.型枠数;
              schedulerData.headers[i].打設数 = bugakari.打設数;
              schedulerData.headers[i].打設量 = bugakari.打設量;
              schedulerData.headers[i].計画人工合計 = bugakari.計画人工合計;
              schedulerData.headers[i].配置予定人工合計 = bugakari.配置予定人工合計;
              schedulerData.headers[i].実施人工合計 = bugakari.実施人工合計;
              schedulerData.headers[i].差異 = bugakari.差異;

              schedulerData.headers[i].休日 = bugakari.休日;
            }
          }

          this.setState({
            viewModel: schedulerData,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  handleToggleExpand = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData
    });
  };

  handleCheckedChanged = (checkedValues) => {
    this.setState({
      checkedValues: checkedValues
    });
  }

  handleScaleChange = (scale) => {
    this.setState({
      scale: scale
    });
  }

  dummy = () => {
    // Nothing
  }

  eventItemTemplateResolver = (schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight, agendaMaxEventWidth) => {

    let value  =  "";
    if (schedulerData.resources.find((v) => v.id === event.resourceId).partflg) {
      value = 
        <div>
          <div key={event.id} className='date_product'>
           <div>{event.出荷日テキスト}</div>
         </div>
        </div>
    }
    else {
      let checkedValue = this.state.checkedValues.find((v) => v.status === event.ステータス); 
      let bgcolor      = checkedValue === undefined ? "white" : checkedValue.color;
      // let color        = checkedValue === undefined ? "#191919" : "white";
      // let color        = "#191919";

      //CHANGED UPDATE 2023/06/30 #17
      let color = checkedValue === undefined ? "black" : blackOrWhite(checkedValue.color);

      value = 
      <div>
        <div key={event.id} className='date_product' style ={{backgroundColor : bgcolor, color : color}}>
          <div>{event.ステータス}</div>
          <div>{event.製品番号}</div>
          <div>{event.立米}</div>
          <div>{event.配合}</div>
          <div>{event.備考}</div>
        </div>
      </div>;
    }

    return (
      value
    );
  }

  slotItemTemplateResolver = (schedulerData, slot, slotClickedFunc, width, clsName) => {
    let indents = [];

    let groupclass = slot.groupOnly === true ? " slot-group" : ""; 

    for (let i = 0; i < slot.indent; i++) {
      indents.push(<span key={`es${i}`} className="expander-space"></span>);
    }

    let indent = <span key={`es${slot.indent}`} className="expander-space"></span>;

    if (slot.hasChildren) {
      indent = slot.expanded ? (
        <Icon type="minus-square" key={`es${slot.indent}`} style={{}} className=""
          onClick={() => {
            this.handleToggleExpand(schedulerData, slot.slotId);
          }} />
      ) : (
          <Icon type="plus-square" key={`es${slot.indent}`} style={{}} className=""
            onClick={() => {
              this.handleToggleExpand(schedulerData, slot.slotId);
            }} />
        );
    }
    indents.push(indent);

    return (
      <div title={slot.slotName} className="overflow-text header2-text" style={{ textAlign: "left" , width: width}}>
        <span className="slot-cell">{indents}
          <span className={"slot-text" + groupclass}>{slot.slotName}</span>
        </span>
      </div>
    );
  }

  nonAgendaCellHeaderTemplateResolver = (schedulerData, item, formattedDateItems, style) => {

    let content;

    let datetime   = schedulerData.localeMoment(item.time);
    let startdate  = new Date(schedulerData.startDate.replace(/-/g, '/'));
    // let enddate    = new Date(schedulerData.endDate.replace(/-/g, '/'));
    let nowdate    = new Date(datetime);
    let i          = ( nowdate - startdate ) / 86400000;
    formattedDateItems[0] = formatDate(nowdate , 'MM/DD');

    if (!this.state.isLoading && schedulerData.events.length > 0 ){


      let isCurrentDate     = false;

      if (schedulerData.viewType === ViewTypes.Day) {
        isCurrentDate = datetime.isSame(new Date(), 'hour');
      }
      else {
        isCurrentDate = datetime.isSame(new Date(), 'day');
      }

      if (isCurrentDate) {
        // style.backgroundColor = '#118dea';
        // style.color = 'white';
      }

      //歩掛データの登録
      let bugakarijson  = {};
      bugakarijson      = schedulerData.headers[i];

      //型枠数、打設数、打設量、回転率
      //型枠数
      let digits      = 1;
      let katawakunum = Number(bugakarijson.型枠数)//.toFixed(digits);
      let dasetsunum  = Number(bugakarijson.打設数)//.toFixed(digits);
      let dasetsuqua  = Number(bugakarijson.打設量).toFixed(digits);
      let kaitenrate  = (Number(dasetsunum) / Number(katawakunum)* 100).toFixed(digits);
      kaitenrate      = isNaN(kaitenrate) ? '0%' :kaitenrate  + '%';

      //人工関連
      let ninku_keikaku = Number(bugakarijson.計画人工合計).toFixed(digits);
      let ninku_haichi  = Number(bugakarijson.配置予定人工合計).toFixed(digits);
      let ninku_jisshi  = Number(bugakarijson.実施人工合計).toFixed(digits);

      //差異を計算
      let boundary      = 20; //％
      let sai           = Number(bugakarijson.差異) !== 0 ? Number(bugakarijson.差異).toFixed(digits) : (0).toFixed(digits);
      let sai_class     = "";
      if(ninku_jisshi > 0){
        if(Number(sai) > 0){
          if( Number(ninku_jisshi) / Number(ninku_keikaku) >= 1 + ( boundary  / 100 ) ){
            sai_class = "bugakari-sai-plus-boundary";
          }else {
            sai_class = "bugakari-sai-plus";
          }
          sai       = "+"  + sai; 
  
        }else if(Number(sai) < 0){
          if( Number(ninku_jisshi) / Number(ninku_keikaku) <= 1 - ( boundary  / 100 ) ){
            sai_class = "bugakari-sai-minus-boundary";
          }else {
            sai_class = "bugakari-sai-minus";
          }
        }
      }else{
        if(Number(sai) > 0){
          if( Number(ninku_haichi) / Number(ninku_keikaku) >= 1 + ( boundary  / 100 ) ){
            sai_class = "bugakari-sai-plus-boundary";
          }else {
            sai_class = "bugakari-sai-plus";
          }
          sai       = "+"  + sai; 
  
        }else if(Number(sai) < 0){
          if( Number(ninku_haichi) / Number(ninku_keikaku) <= 1 - ( boundary  / 100 ) ){
            sai_class = "bugakari-sai-minus-boundary";
          }else {
            sai_class = "bugakari-sai-minus";
          }
        }
      }


      content =
      <div className={'bugakari-data'}>
        <div>{dasetsunum}</div>
        <div>{dasetsuqua}</div>
        <div>{katawakunum}</div>
        <div>{kaitenrate}</div>
        <div>{ninku_keikaku}</div>
        <div>{ninku_haichi}</div>
        <div>{ninku_jisshi}</div>
        <div className={sai_class}>{sai}</div>
      </div>
    } else {
      content =
        <div className={'bugakari-data'}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
    }

    return (
      <th key={item.time} className={`header3-text`} style={style}>
        {
          formattedDateItems.map((formattedItem, index) => (
            <div key={index}
              className={index === 0 ? 'header-day' : 'header-dayweek'}
              dangerouslySetInnerHTML={{ __html: formattedItem }} />
          ))
        }
        {content}
      </th>
    );
  };

  getDateLabel = (schedulerData, viewType, startDate, endDate) => {
    let start = schedulerData.localeMoment(startDate);
    let end = schedulerData.localeMoment(endDate);
    let dateLabel = start.format('YYYY年M月D日');

    if (viewType === ViewTypes.Week) {
      dateLabel = `${start.format('YYYY年M月D日')}-${end.format('D日')}`;
      if (start.month() !== end.month())
        dateLabel = `${start.format('YYYY年M月D日')}-${end.format('M月D日')}`;
      if (start.year() !== end.year())
        dateLabel = `${start.format('YYYY年M月D日')}-${end.format('YYYY年M月D日')}`;
    }
    else if (viewType === ViewTypes.Month) {
      dateLabel = start.format('YYYY年M月');
    }
    else if (viewType === ViewTypes.Quarter) {
      dateLabel = `${start.format('YYYY年M月D日')}-${end.format('M月D日')}`;
    }
    else if (viewType === ViewTypes.Year) {
      dateLabel = start.format('YYYY年');
    }
    else if (viewType === ViewTypes.Custom) {
      dateLabel = start.add(15, 'days').format('YYYY年M月D日の週');
    }

    return dateLabel;
  };

  getCustomDate = (schedulerData, num, date = undefined) => {
    let selectDate = schedulerData.startDate;

    if (date !== undefined)
      selectDate = date;

    let startDate = schedulerData.localeMoment(selectDate).add(-15, 'days').format(DATE_FORMAT),
      endDate = schedulerData.localeMoment(selectDate).add(15, 'days').format(DATE_FORMAT),
      cellUnit = CellUnits.Day;

    return {
      startDate,
      endDate,
      cellUnit
    };
  };

  getFMSchdules = async (startDate, endDate, selectedSort, selectedLine, selectedArticle) => {
    let schedulerData = this.state.viewModel;
    let resources = [];
    let events = [];
    let bikoujson = null;
    let syukkajson = null;
    let schedulerParam = null;
    let scriptResult = null; 
    let lines = [];
    let articles = [];
    let linestatus = [];
    let checkedValues = [];

    // CHANGED:ADD #12
    linestatus.push({code: 0, status: "", color: "#FFFFFF" });

    try {
      schedulerParam = await this.getFMSchdulesDataAPI(startDate, endDate, selectedSort, selectedLine, selectedArticle);
      const code = parseInt(schedulerParam.messages[0].code, 10);
      if (code !== 0) {
        return {
          schedulerData,
          linestatus,
          lines,
          articles,
          checkedValues
        };
      }

      //スクリプトの結果を取得
      scriptResult = JSON.parse(decodeURIComponent(escape(window.atob(schedulerParam.response['scriptResult']))));
      const error_code = parseInt(scriptResult.error_code, 10);
      if (error_code !== 0) {
        return {
          schedulerData,
          linestatus,
          lines,
          articles,
          checkedValues
        };
      }
    } catch {
      return {
        schedulerData,
        linestatus,
        lines,
        articles,
        checkedValues
      };
    }

    //リソースファイルの取得
    resources = JSON.parse(scriptResult.resources);

    //備考の取得
    bikoujson = JSON.parse(scriptResult.bikou);

    //################################
    //イベントファイルの設定
    //################################
    const fmevents = schedulerParam.response.data;
    events          = [];
    let eventdate   = new Date();
    let eventsstart = new Date();
    let eventsend   = new Date();
    let fieldData   = {};
    let resourceId  = "";
    let bikou       = {};

    for (var i in fmevents) {
      //ループ中のフィールドデータを取得
      fieldData = fmevents[i].fieldData;

      //フィールドデータを挿入
      events[i] = {};
      events[i] = fieldData;

      //日付
      eventdate        = new Date(fieldData.打設完了予定日);
      eventsstart      = formatDate(eventdate,"YYYY/MM/DD");
      eventsend        = formatDate(new Date(eventdate.setDate(eventdate.getDate() + 1)),"YYYY/MM/DD");
      // _eventsstart = formatDate(new Date(fieldData.打設完了予定日),"YYYY/MM/DD");
      // _eventsend   = eventsstart.setDate(eventsstart.getDate() + 1);
      resourceId = (selectedSort === "ライン別") ? fieldData.c_ライン別_型枠番号 : fieldData.c_物件別_型枠番号;

      events[i].id         = fieldData.UUID;
      events[i].start      = eventsstart;
      events[i].end        = eventsend;
      events[i].resourceId = resourceId;
      events[i].title      = "";

      //備考の取得
      bikou                = bikoujson[resourceId];
      if (bikou !== undefined) {
        bikou = bikou[eventsstart];

        if (bikou !== undefined) {
          events[i].備考   = bikou.text;
          events[i].備考UUID = bikou.id;

          delete bikoujson[resourceId][eventsstart];
          if ((Object.keys(bikoujson[resourceId]).length) === 0) {
            delete bikoujson[resourceId];
          }
        }
      }
    }

    //################################
    //製品に紐づかない、単独の備考を登録する
    //################################
    const bikou_keys     = Object.keys(bikoujson);
    const bikou_len      = bikou_keys.length;
    let   bikou_loop_cnt = Number(i) + 1;

    for (let bikou_cnt = 0; bikou_cnt < bikou_len; bikou_cnt++) {

      let bikou_key       = bikou_keys[bikou_cnt];
      let bikou_date_keys = Object.keys(bikoujson[bikou_key]);
      let bikou_date_len  = bikou_date_keys.length;

      for (let bikou_date_cnt = 0; bikou_date_cnt < bikou_date_len; bikou_date_cnt++) {
        let bikou_date       = new Date(bikou_date_keys[bikou_date_cnt])
        let bikou_start      = formatDate(bikou_date, "YYYY/MM/DD");
        let bikou_end        = formatDate(new Date(bikou_date.setDate(bikou_date.getDate() + 1)), "YYYY/MM/DD");
        let bikou_id         = bikoujson[bikou_key][bikou_start].id;
        let bikou_resourceId = bikoujson[bikou_key][bikou_start].resourceId;
        let bikou_text       = bikoujson[bikou_key][bikou_start].text;
        // let bikou_date     = Object.keys(bikoujson[bikou_key]);
        // let bikou_date_len = bikou_date.length;
        events[bikou_loop_cnt]            = {};
        events[bikou_loop_cnt].id         = bikou_id;
        events[bikou_loop_cnt].備考        = bikou_text;
        events[bikou_loop_cnt].備考UUID    = bikou_id;
        events[bikou_loop_cnt].start      = bikou_start;
        events[bikou_loop_cnt].end        = bikou_end;
        events[bikou_loop_cnt].resourceId = bikou_resourceId;
        events[bikou_loop_cnt].title      = "";

        bikou_loop_cnt++;
      }
    }

    //出荷日テキストの取得
    syukkajson = JSON.parse(scriptResult.syukka);

    //################################
    //出荷日テキスト設定
    //################################
    const syukka_keys = Object.keys(syukkajson);
    const syukka_len = syukka_keys.length;
    let syukka_loop_cnt = bikou_loop_cnt;

    for (let syukka_cnt = 0; syukka_cnt < syukka_len; syukka_cnt++) {

      let syukka_key = syukka_keys[syukka_cnt];
      let syukka_date_keys = Object.keys(syukkajson[syukka_key]);
      let syukka_date_len = syukka_date_keys.length;

      for (let syukka_date_cnt = 0; syukka_date_cnt < syukka_date_len; syukka_date_cnt++) {
        let syukka_date = new Date(syukka_date_keys[syukka_date_cnt])
        let syukka_start = formatDate(syukka_date, "YYYY/MM/DD");
        let syukka_end = formatDate(new Date(syukka_date.setDate(syukka_date.getDate() + 1)), "YYYY/MM/DD");
        let syukka_id = syukkajson[syukka_key][syukka_start].id;
        let syukka_resourceId = syukkajson[syukka_key][syukka_start].resourceId;
        let syukka_text = syukkajson[syukka_key][syukka_start].text;
        // let syukka_date     = Object.keys(syukkajson[syukka_key]);
        // let syukka_date_len = syukka_date.length;
        events[syukka_loop_cnt] = {};
        events[syukka_loop_cnt].id = syukka_id;
        events[syukka_loop_cnt].出荷日テキスト = syukka_text;
        events[syukka_loop_cnt].出荷日テキストUUID = syukka_id;
        events[syukka_loop_cnt].start = syukka_start;
        events[syukka_loop_cnt].end = syukka_end;
        events[syukka_loop_cnt].resourceId = syukka_resourceId;
        events[syukka_loop_cnt].title = "";

        syukka_loop_cnt++;
      }
    }

    //################################
    //歩掛設定・休日設定
    //################################
    const bugakarijson = JSON.parse(scriptResult.bugakari);

    for (let i = 0; i < schedulerData.headers.length; i++) {
      let date = schedulerData.localeMoment(schedulerData.headers[i].time);
      let header_date = formatDate(new Date(date), "YYYY/MM/DD");
      let bugakari = bugakarijson[header_date];

      if (bugakari) {
        if (bugakari.休日 !== true) {
          schedulerData.headers[i].nonWorkingTime = false;
        } else {
          schedulerData.headers[i].nonWorkingTime = true;
        }

        schedulerData.headers[i].型枠数         = bugakari.型枠数;
        schedulerData.headers[i].打設数         = bugakari.打設数;
        schedulerData.headers[i].打設量         = bugakari.打設量;
        schedulerData.headers[i].計画人工合計    = bugakari.計画人工合計;
        schedulerData.headers[i].配置予定人工合計 = bugakari.配置予定人工合計;
        schedulerData.headers[i].実施人工合計    = bugakari.実施人工合計;
        schedulerData.headers[i].差異           = bugakari.差異;

        schedulerData.headers[i].休日           = bugakari.休日;
      }
    }

    //################################
    //製造ラインステータス設定
    //################################
    const linestatusjson = JSON.parse(scriptResult.linestatus);

    linestatusjson.forEach(item => {
      var status = {
        code: item.code,
        status: item.status,
        color: item.color
      }

      linestatus.push(status);
      checkedValues.push(status);
    });

    //################################
    //製造ライン設定
    //################################
    if ((selectedSort === "ライン別" && selectedLine === null && selectedArticle === null) ||
      (selectedSort === "物件別" && selectedLine === null)) {
      const lineMap = [...new Map(events.map((v) => [v.製造ラインコード, v]).sort()).values()];

      lineMap.forEach(item => {
        if (item.製造ラインコード) {
          var line = {
            value: item.製造ラインコード,
            label: hankaku2Zenkaku(item.製造ライン名称)
          }
          lines.push(line);
        }
      });
    } else {
      lines = this.state.lines;
    }

    //################################
    //物件設定
    //################################
    if ((selectedSort === "物件別" && selectedLine === null && selectedArticle === null) ||
      (selectedSort === "ライン別" && selectedArticle === null)) {
      const articleMap = [...new Map(resources.map((v) => [v.name, v]).sort()).values()];

      articleMap.forEach(item => {
        if (item.articleflg) {
          var article = {
            value: item.id,
            label: item.name
          }
          articles.push(article);
        }
      });
    } else {
      articles = this.state.articles;
    }

    //################################
    //結果を返す
    //################################
    schedulerData.setResources(resources);
    schedulerData.setEvents(events);

    return {
      schedulerData,
      linestatus,
      lines,
      articles,
      checkedValues
    };
  }

  getFMSchdulesDataAPI = async (startdate, enddate, selectedSort, selectedLine, selectedArticle) => {
    let layout = 'D1002_製品情報取得_mst製品';

    //検索設定
    let _startdate = formatDate(new Date(startdate), 'MM/DD/YYYY');
    let _enddate   = formatDate(new Date(enddate),   'MM/DD/YYYY');

    let field = {
      '打設完了予定日': _startdate + '...' + _enddate,
      '削除済みフラグ': 0,
      '製造ラインコード': selectedLine === null ? '' : selectedLine.value,
      '物件UUID': selectedArticle === null ? '' : selectedArticle.value
    };

    //検索の最大件数
    let limit = 1000;

    //スクリプト
    let script = 'D1002_WEB工程表_データ生成[DataAPIMap]';
    let scriptparam = {
      'sort': selectedSort,
      'start': formatDate(new Date(startdate), 'YYYY/MM/DD'),
      'end': formatDate(new Date(enddate), 'YYYY/MM/DD'),
      '製造ラインコード': selectedLine === null ? '' : selectedLine.value,
      '物件UUID': selectedArticle === null ? '' : selectedArticle.value,
      従業員UUID: User.getUserId()  
    }

    //FMパラメータ生成
    let json = {
      'layout': layout,
      'data': {
        'query': [field],
        'limit': limit,
        'script': script,
        'script.param': new Buffer(JSON.stringify(scriptparam)).toString('base64')
      }
    };
    const schedulesresult = (await axios.post(API_URL + '/filemaker', json)).data;

    return schedulesresult;
  }

  getFMBugakariDataAPI = async (startdate, enddate, selectedSort, lines, articles) => {
    let layout = 'D1002_製品情報取得_mst製品';

    //検索設定
    let _startdate = formatDate(new Date(startdate), 'MM/DD/YYYY');
    let _enddate   = formatDate(new Date(enddate),   'MM/DD/YYYY');
    let field = {
      '打設完了予定日': _startdate + '...' + _enddate,
      '削除済みフラグ': 0
    };

    //検索の最大件数
    let limit = 1000;

    //スクリプト
    let script = 'D1002_WEB工程表_データ生成[DataAPIMap]';
    let scriptparam = {
      'sort': selectedSort,
      'start': formatDate(new Date(startdate), 'YYYY/MM/DD'),
      'end': formatDate(new Date(enddate), 'YYYY/MM/DD'),
      '製造ラインコード': lines,
      '物件UUID': articles
    }

    //FMパラメータ生成
    let json = {
      'layout': layout,
      'data': {
        'query': [field],
        'limit': limit,
        'script': script,
        'script.param': new Buffer(JSON.stringify(scriptparam)).toString('base64')
      }
    };
    const schedulesresult = (await axios.post(API_URL + '/filemaker', json)).data;

    return schedulesresult;
  }

  render() {
    const { viewModel, clickEventModalIsOpen, moveEventModalIsOpen, modalParam, linestatus, lines, articles, selectedSort, selectedLine, selectedArticle, checkedValues, scale, isLoading } = this.state;
    const navBarProps = {
      schedulerData: viewModel,
      linestatus: linestatus,
      lines: lines,
      articles: articles,
      selectedSort: selectedSort,
      selectedLine: selectedLine,
      selectedArticle: selectedArticle,
      checkedValues: checkedValues,
      scale: scale,
      handleNextClick: this.handleNextClick,
      handlePrevClick: this.handlePrevClick,
      handleSelectDate: this.handleSelectDate,
      handleViewChange: this.handleViewChange,
      handleSortChange: this.handleSortChange,
      handleLineChange: this.handleLineChange,
      handleArticleChange: this.handleArticleChange,
      handleCheckedChanged: this.handleCheckedChanged,
      handleScaleChange: this.handleScaleChange
    }
    const clickEventModalProps = {
      modalIsOpen: clickEventModalIsOpen,
      schedulerData: viewModel,
      modalParam: modalParam,
      linestatus: linestatus,
      refStatus: this.clickEventRefStatus,
      refText: this.clickEventRefText,
      handleClickEventCloseModal: this.handleClickEventCloseModal,
      handleSelectedDateUpdateButtonClicked: this.handleSelectedDateUpdateButtonClicked,
      handleBikouUpdateButtonClicked: this.handleBikouUpdateButtonClicked,
      handleSyukkaUpdateButtonClicked: this.handleSyukkaUpdateButtonClicked
    }
    const moveEventModalProps = {
      modalIsOpen: moveEventModalIsOpen,
      schedulerData: viewModel,
      modalParam: modalParam,
      handleMoveEventCloseModal: this.handleMoveEventCloseModal,
      handleSelectedDateMoveButtonClicked: this.handleSelectedDateMoveButtonClicked,
      handleSelectedDateAndLaterMoveButtonClicked: this.handleSelectedDateAndLaterMoveButtonClicked,
      handleKatawakuMoveButtonClicked: this.handleKatawakuMoveButtonClicked
    }
    const scalesize = {
      fontSize: (16 * scale ).toFixed(0) +'px'
      // transformOrigin: 'top left',
      // height: '240px'
    }
    viewModel.config.monthCellWidth = Number((viewModel.config.defaultCellWidth * scale).toFixed(0));
    viewModel.config.customCellWidth = Number((viewModel.config.defaultCellWidth * scale).toFixed(0));

    //歩掛集計
    let width         = viewModel.getResourceTableWidth();
    let height        = viewModel.config.tableHeaderHeight;
    let t_katawakunum;
    let t_dasetsunum;
    let t_dasetsuqua;
    let t_kaitenrate;

    let t_ninku_keikaku;
    let t_ninku_haichi;
    let t_ninku_jisshi;
    let t_sai;
    let t_sai_class;

    if(isLoading === false){

      let digits = 1;
      t_katawakunum   = viewModel.headers.reduce((p, v) => p + v.型枠数, 0)//.toFixed(digits);;
      t_dasetsunum    = viewModel.headers.reduce((p, v) => p + v.打設数, 0)//.toFixed(digits);;
      t_dasetsuqua    = viewModel.headers.reduce((p, v) => p + v.打設量, 0).toFixed(digits);
      t_kaitenrate    = (Number(t_dasetsunum) / Number(t_katawakunum)*100).toFixed(digits);
      t_kaitenrate    = isNaN(t_kaitenrate) ? '0%' :t_kaitenrate  + '%';
  
      t_ninku_keikaku = (viewModel.headers.reduce((p, v) => p + Number(v.計画人工合計), 0)).toFixed(digits);
      t_ninku_haichi  = (viewModel.headers.reduce((p, v) => p + Number(v.配置予定人工合計), 0)).toFixed(digits);
      t_ninku_jisshi  = (viewModel.headers.reduce((p, v) => p + Number(v.実施人工合計), 0)).toFixed(digits);

      //差異を計算
      let boundary      = 20; //％
      t_sai           = (viewModel.headers.reduce((p, v) => p + Number(v.差異), 0)).toFixed(digits);
      t_sai_class     = "";
      if(Number(t_sai) > 0){
        if( Number(t_ninku_jisshi) / Number(t_ninku_keikaku) >= 1 + ( boundary  / 100 ) ){
          t_sai_class = "bugakari-sai-plus-boundary";
        } else {
          t_sai_class = "bugakari-sai-plus";
        }
        t_sai       = "+"  + t_sai; 
      }else if(Number(t_sai) < 0){
        if( Number(t_ninku_jisshi) / Number(t_ninku_keikaku) <= 1 - ( boundary  / 100 ) ){
          t_sai_class = "bugakari-sai-minus-boundary";
        } else {
          t_sai_class = "bugakari-sai-minus";
        }
      }
    }
    let resourcename =
      <div className={"resource-table-fixed"} style={{ width: width, height: height }}>
        <table className={"resource-table"}>
          <tbody>
            <tr style={{ height: height }}>
              <th className={"header3-text"}>
                <div className="header-label">
                  <div className={"header-label-date"}>
                    <span>{"日付"}</span>
                    <span>{"集計"}</span>
                  </div>
                  <div>
                    <span>{"総打設数(P)"}</span>
                    <span>{t_dasetsunum}</span>
                  </div>
                  <div>
                    <span>{"総打設量(㎥)"}</span>
                    <span>{t_dasetsuqua}</span>
                  </div>
                  <div>
                    <span>{"総型枠数(P)"}</span>
                    <span>{/*t_katawakunum*/}</span>
                  </div>
                  <div>
                    <span>{"回転率"}</span>
                    <span>{t_kaitenrate}</span>
                  </div>
                  <div>
                    <span>{"計画人工合計(人工)"}</span>
                    <span>{t_ninku_keikaku}</span>
                  </div>
                  <div>
                    <span>{"配置予定人工合計(人工)"}</span>
                    <span>{t_ninku_haichi}</span>
                  </div>
                  <div>
                    <span>{"実施人工合計(人工)"}</span>
                    <span>{t_ninku_jisshi}</span>
                  </div>
                  <div>
                    <span>{"差異(人工)"}</span>
                    <span className={t_sai_class}>{t_sai}</span>
                  </div>
                </div>
              </th>
            </tr>
          </tbody>
        </table>
      </div>;

    return (
      <div>
        {/* <!-- ヘッダー --> */}
        <NavBar {...navBarProps} />
        {/* <!-- ボディ --> */}
        <div id={"scheduler"} style={scalesize}>
          {resourcename}
          <div>
            <BigScheduler
              schedulerData={viewModel}
              prevClick={this.dummy}
              nextClick={this.dummy}
              onSelectDate={this.dummy}
              onViewChange={this.dummy}
              eventItemClick={this.eventClicked}
              moveEvent={this.moveEvent}
              newEvent={this.newEvent}
              eventItemTemplateResolver={this.eventItemTemplateResolver}
              slotItemTemplateResolver={this.slotItemTemplateResolver}
              nonAgendaCellHeaderTemplateResolver={this.nonAgendaCellHeaderTemplateResolver}
            />
          </div>
        </div>
        {/* <!-- ローディング --> */}
        {isLoading &&
          <Loading />
        }
        {/* <!-- モーダル --> */}
        {clickEventModalIsOpen &&
          <ClickEventModal {...clickEventModalProps} />
        }
        {moveEventModalIsOpen &&
          <MoveEventModal {...moveEventModalProps} />
        }
      </div>
    );
  }
}

export default DragDropContext(HTML5Backend)(Scheduler);